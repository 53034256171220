import { getURLSearchParam, removeURLSearchParam } from  "../../lib/iprs-react-library/src/index";

const RECAPTCHA_URL = process.env.REACT_APP_GOOGLE_RECAPTCHA_URL;
const RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

const getSiteURL = () => {
    const loc = window.location.origin + window.location.pathname;
    const swappedOutBeta = loc.replace("beta-onlinediary.iprsapps.com", "onlinediary.iprsapps.com");
    return swappedOutBeta;
}

/**
 * Get the config for this website. This passes a token and webID as well
 * as the canonical URL to the API to get a config. The Config will come back
 * with values
 * @returns {undefined}
 */
const bootstrap = (api, doUpdate) => {
    const token = getURLSearchParam("token");

    removeURLSearchParam("webID");
    // make it easy to test the token
    if(!getURLSearchParam("HoldTokenParam")){
        removeURLSearchParam("token");
    }
    removeURLSearchParam("caseID");

    const loc = getSiteURL();

    /* use the token in the URL but otherwise use the one */
    //const tokenToUse = token? token: api.token;
    const tokenToUse = token ? token : null;

    api.getBookingSiteConfig(tokenToUse, null, loc)
        .then(r => r.apiResult)
        .then(webConfig => {
            if (webConfig.Token) {
                api.setToken(webConfig.Token);
                doUpdate({ websiteConfig: webConfig });
                loadLiveHelp(webConfig);
            } else if (webConfig.RecaptureRequired) {
                loadRecaptcha()
                    .then(r => doRecaptcha())
                    .then(reCaptchaToken =>
                        api.getBookingSiteConfig(
                            tokenToUse,
                            reCaptchaToken,
                            loc
                        )
                    )
                    .then(r => r.apiResult)
                    .then(newWebConfig => {
                        if (newWebConfig.Token) {
                            api.setToken(newWebConfig.Token);
                            doUpdate({ websiteConfig: newWebConfig });
                            loadLiveHelp(newWebConfig);
                        } else {
                            //user failed recaptcha
                            const err = newWebConfig.RecaptureRequired
                                ? "Sorry there was a problem with website protection"
                                : "Sorry there was an error";

                            doUpdate({ error: err });
                        }
                    })
                    .catch(error =>
                        doUpdate({ error: "Sorry there was an error" })
                    );
            } else {
                // The user did need a token on the URL here
                doUpdate({
                    error: "Please use the link you received by email"
                });
            }
        })
        .catch(err => {
            doUpdate({ error: "Sorry there was an error" });
        });
};

const doRecaptcha = () => {
    return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(RECAPTCHA_KEY, { action: "submit" })
                .then(token => resolve(token));
        });
    });
};

const loadRecaptcha = () => {
    const id = "recaptchaEl";
    const scriptLocation = document.getElementById(id);

    if (!scriptLocation) {
        return new Promise((resolve, reject) => {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = RECAPTCHA_URL + RECAPTCHA_KEY;
            script.id = id;
            script.onload = () => {
                return resolve(true);
            };
            document.body.appendChild(script);
        });
    } else {
        return Promise.resolve(true);
    }
};

/* If enabled, Add the live help function to the site. This is just added
 * without waiting as we don't need to do anything other than add the script.*/
const loadLiveHelp = websiteConfig => {
    const id = "getsyn";
    const scriptLocation = document.getElementById(id);

    if (
        websiteConfig.ShowLiveHelp &&
        websiteConfig.LiveHelpURL &&
        !scriptLocation
    ) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = websiteConfig.LiveHelpURL;
        script.id = id;
        script.async = true;
        script.onload = () => {
            window.synthetix();
        };
        script.onerror = (message, source, lineno, colno, error) => {
            console.log("chat script failed");
        };
        document.body.appendChild(script);
    }
};

export { bootstrap };
