import toBoolean from "../../lib/iprs-react-library/src/util/toBoolean";
import DataCapture, {
    FormStateConsts
} from "../FormDesigner/Form/DataCapture";
import updateWithConfig from "./updateWithConfig";

/**
 * The main processor of application state. See useReducer in the App and the REACT
 * docs for useReducer.
 * @param {Object} currentState The current application state
 * @param {Object} captured An update or state change action. Often captured data
 * from user input.
 * @returns {Object} The updated state
 */
const reducer = (currentState, captured) => {

    let newDC = { ...currentState };

    if (captured?.error) {
        newDC.error = captured.error;
    }
    if (captured?.clearError) {
        newDC.error = null;
    }

    if (captured.handleLoginEvent) {
        //setCapturedData( newDC );
        return newDC;
    }

    if (
        captured.websiteConfig &&
        (newDC.pageState === FormStateConsts.Bootstraping ||
            newDC.pageState === FormStateConsts.Configuration ||
            newDC.configMode)
    ) {
        updateWithConfig(captured.websiteConfig, newDC);
    }

    switch (newDC.pageState) {
        case FormStateConsts.Bootstraping:
            if (captured.error) {
                newDC.pageState = FormStateConsts.Error;
            } else if (1 < captured.websiteConfig.Clients?.length) {
                newDC.pageState = FormStateConsts.ClientCapture;
            } else if (
                toBoolean(captured.websiteConfig.CaptureAssessmentType)
            ) {
                if (!newDC.CaseID) {
                    newDC.pageState = FormStateConsts.PersonCapture;
                } else {
                    newDC.pageState = FormStateConsts.AssessmentTypeCapture;
                }
            } else if (
                newDC.appointmentType &&
                !newDC.captureInjuries &&
                !newDC?.websiteConfig?.CaptureVisual
            ) {
                newDC.pageState = FormStateConsts.TimeCapture;
            } else {
                // we will skip through sections that are done
                newDC.pageState = FormStateConsts.ClientCapture;
            }

            break;
        case FormStateConsts.ClientCapture:
            newDC.Client = captured.Client;

            if (newDC.Client?.ClientPartyID) {
                newDC.ServiceActivities = filterServiceActivities(
                    newDC.ServiceActivities,
                    newDC.Client.ClientPartyID
                );
                if (newDC.ServiceActivities?.length > 1) {
                    newDC.pageState = FormStateConsts.ServiceCapture;
                } else {
                    newDC.activeStages = newDC.activeStages.filter(
                        as => as[0] !== FormStateConsts.ServiceCapture
                    );
                    if (
                        newDC.appointmentType &&
                        !newDC.captureInjuries &&
                        !newDC?.websiteConfig?.CaptureVisual
                    ) {
                        newDC.pageState = FormStateConsts.TimeCapture;
                    } else {
                        newDC.pageState = FormStateConsts.AppointmentCapture;
                    }
                }
            }

            break;
        case FormStateConsts.ServiceCapture:
            newDC.serviceActivityId = captured.serviceActivityId;
            newDC.pageState = FormStateConsts.AppointmentCapture;
            break;
        case FormStateConsts.AssessmentTypeCapture:
            // nothing to do here. We don't store the assesment type
            // as if it wheren't ClinicalAssesment we would have already
            // redirected to Phio Access
            newDC.AssesmentType = captured.AssesmentType ?? newDC.AssesmentType;

            // some duplication here
            newDC.CaseID = captured.CaseID ?? newDC.CaseID;
            newDC.CaseRef = captured.CaseRef ?? newDC.CaseRef;
            newDC.caseID = captured.CaseID ?? newDC.CaseID;
            newDC.caseRef = captured.CaseRef ?? newDC.CaseRef;

            break;
        case FormStateConsts.AppointmentCapture:
            newDC.appointmentType = captured.appointmentType;
            newDC.appointmentTypes = captured.appointmentTypes;
            newDC.injuries = captured.injuries;
            newDC.isVisual = captured.isVisual;

            if (newDC.appointmentType) {
                newDC.pageState = FormStateConsts.TimeCapture;
            } else {
                newDC.pageState = FormStateConsts.PersonCapture;
            }
            //setCapturedData( newDC );

            break;
        case FormStateConsts.TimeCapture:
            // this will have been signalled in holdBookingSlot
            if (captured.holdBookingID) {
                newDC.bookingId = captured.holdBookingID;
                if (captured.StartTime) {
                    newDC.startTime = captured.StartTime;
                    newDC.finishTime = captured.FinishTime;
                }

                if (newDC.caseID) {
                    newDC.pageState = FormStateConsts.Review;
                } else {
                    newDC.pageState = FormStateConsts.PersonCapture;
                }
            }

            break;
        case FormStateConsts.PersonCapture:
            // personal data not stored with rest of data
            //setPersonalData( captured )

            if (captured.personalData) {
                newDC.personalData = captured.personalData;
                newDC.personalDataHasBeenCaptured = true;
            }

            break;
        case FormStateConsts.PaymentCapture:
            newDC.CardPaymentCheckDone = 
            captured.CardPaymentCheckDone? 
            true : newDC.CardPaymentCheckDone;
            break;
        case FormStateConsts.Review:
            if (captured?.doCancel) {
                // always go back to time (decision meeting 21-11-26)
                newDC.pageState = FormStateConsts.TimeCapture;
                newDC.bookingId = null; // clear booking id so we can book again
            } else if (captured.allDone) {
                // All done passes the caseRef
                newDC.caseRef = captured.CaseRef;
                newDC.caseID = captured.CaseID;

                // all done signalled from finaliseReferralAndBooking
                newDC.pageState = FormStateConsts.Finish;
            } else if (captured.error) {
                newDC.pageState = FormStateConsts.Error;
                newDC.error =
                    "We are very sorry. There was an error while saving your booking. Please contact IPRS on 0800 072 1227 selecting option 1.";
            }
            break;
        case FormStateConsts.Finish:
            newDC.pageState = FormStateConsts.AppointmentCapture;

            if (captured?.makeNewBooking) {
                /* Create a fresh state and run the whole bootstrapping process
                 * again. Better to do the whole process again and get a new
                 * guest token if necessary as they have a fixed time. */
                newDC = new DataCapture();
                newDC.api = currentState.api;
                newDC.configMode = currentState.configMode;
                /* setting this will trigger useEffect in the App */
                newDC.pageState = FormStateConsts.Bootstraping;
            }

            break;

        case FormStateConsts.Error:
            break;
        case FormStateConsts.Configuration:
        default:
    }

    if (captured.changePageState) {
        newDC.pageState = captured.changePageState;
    }

    //console.log("moving on", newDC.pageState);
    newDC = moveOn(newDC);
    //console.log("moved on", newDC.pageState);

    return {...newDC};
};

const moveOn = state => {
    const moveToNextStage = state => {
        const stages = state?.activeStages?.map(as => as[0]);
        const currentStage = state.pageState;

        const getNextStage = () => {
            const currentStageIndex = stages.indexOf(currentStage);
            const nextStageIndex = currentStageIndex + 1;
            const nextStage = stages[nextStageIndex];
            return nextStage;
        }

        switch (currentStage) {
            case FormStateConsts.ClientCapture:
                if (stages.includes(FormStateConsts.ClientCapture)) {
                    const ClientPartyID = state.Client?.ClientPartyID;
                    if (ClientPartyID) {
                        return getNextStage();
                    } else {
                        return FormStateConsts.ClientCapture;
                    }
                } else {
                    return getNextStage();;
                }
            case FormStateConsts.ServiceCapture:
                if (stages.includes(FormStateConsts.ServiceCapture)) {
                    const serviceActivityId = state.serviceActivityId;
                    if (serviceActivityId) {
                        return getNextStage();
                    } else {
                        return FormStateConsts.ServiceCapture;
                    }
                } else {
                    return getNextStage();
                }
            case FormStateConsts.AssessmentTypeCapture:
                if (stages.includes(FormStateConsts.AssessmentTypeCapture)) {
                    const assesmentType = state.AssesmentType;
                    if (assesmentType) {
                        return getNextStage();
                    } else {
                        return FormStateConsts.AssessmentTypeCapture;
                    }
                } else {
                    return FormStateConsts.PaymentCapture;
                }
            case FormStateConsts.PaymentCapture:
                if (stages.includes(FormStateConsts.PaymentCapture)) {
                    const CardPaymentCheckDone = state.CardPaymentCheckDone;
                    if (CardPaymentCheckDone) {
                        return getNextStage();
                    } else {
                        return FormStateConsts.PaymentCapture;
                    }
                } else {
                    return FormStateConsts.AppointmentCapture;
                }
            case FormStateConsts.AppointmentCapture:
                if (stages.includes(FormStateConsts.AppointmentCapture)) {
                    const appointmentType = state.appointmentType;
                    if (appointmentType &&
                        (!state.websiteConfig?.CaptureVisual || Object.hasOwn(appointmentType, "isVisual")) &&
                        (!state.websiteConfig?.CaptureInjuries || Object.hasOwn(appointmentType, "injuries"))){
                        return getNextStage();
                    } else {
                        return FormStateConsts.AppointmentCapture;
                    }
                } else {
                    return FormStateConsts.TimeCapture;
                }
            case FormStateConsts.TimeCapture:
                if (stages.includes(FormStateConsts.TimeCapture)) {
                    const bookingId = Number.parseInt(state.bookingId);
                    if (bookingId > 0) {
                        return getNextStage();
                    } else {
                        return FormStateConsts.TimeCapture;
                    }
                } else {
                    return FormStateConsts.PersonCapture;
                }
            case FormStateConsts.PersonCapture:
                if (stages.includes(FormStateConsts.PersonCapture)) {
                    if (state.personalDataHasBeenCaptured) {
                        return getNextStage();
                    } else {
                        return FormStateConsts.PersonCapture;
                    }
                } else {
                    return getNextStage();
                }
            default:
                return currentStage;
        }
    };

    /* Moving on just skips stages that are complete or don't need to be done.
    We don't do it in config mode as the config system lets the user see or edit some of the sections. */
    if (!state.configMode) {
        // iterate until stage doesn't change
        let nextStage = moveToNextStage(state);
        while (nextStage !== state.pageState) {
            //console.log("moving on to", nextStage, state.pageState);
            state.pageState = nextStage;
            nextStage = moveToNextStage(state);
        }
    }

    return state;
};

/** return just service activities for the clientID */
const filterServiceActivities = (serviceActivities, clientID) => {
    if( !clientID ){
        return serviceActivities;
    }else{
        return serviceActivities.filter(sa => Array.isArray( sa.ClientPartyIDs )? sa.ClientPartyIDs.includes(clientID) : true);       
    }   
}

export default reducer;
