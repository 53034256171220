const demoMockNetwork = (messageID, params) => {
    let result;
    switch (messageID) {
        case "ValidateToken":
            if ("valid-token" === params.Token) {
                result = {
                    Status: "OK",
                    Text: "",
                    Result: params.Token,
                    Cookie: "Token"
                };
            } else {
                result = {
                    Status: "ERROR",
                    Text: "Invalid token.",
                    Result: ""
                };
            }
            break;
        case "Authenticate":
            result = {
                Status: "OK",
                Text: "",
                Result: "Your login token",
                Cookie: "Token"
            };
            break;
        case "PersonLoad":
            result = {
                Status: "OK",
                Text: "",
                Result: [
                    {
                        pkPartyID: 123456,
                        LastName: "LastName",
                        FirstName: "FirstName",
                        MiddleNames: "",
                        PersonalTitle: "Mr"
                    }
                ]
            };
            break;
        case "GetReferreeDetails":
            result = {
                Status: "OK",
                Text: "",
                Result: {
                    ClientList: [
                        {
                            Name: "Client 1",
                            PartyID: 11
                        },
                        {
                            Name: "Client 2",
                            PartyID: 12
                        }
                    ]
                }
            };
            break;
        case "GetReferralForm":
            if (11 === params.ClientID) {
                result = {
                    Status: "OK",
                    Text: "",
                    Result: [
                        {
                            ReferralPage: {
                                Field: [
                                    {
                                        name: "AField1",
                                        text: "A Field 1"
                                    },
                                    {
                                        name: "AField2",
                                        text: "A Field 2"
                                    }
                                ]
                            },
                            ConfigTitle: "Form A"
                        },
                        {
                            ReferralPage: {
                                Field: [
                                    {
                                        name: "BField1",
                                        text: "B Field 1"
                                    },
                                    {
                                        name: "BField2",
                                        text: "B Field 2"
                                    }
                                ]
                            },
                            ConfigTitle: "Form B"
                        }
                    ]
                };
            } else {
                result = {
                    Status: "OK",
                    Text: "",
                    Result: [
                        {
                            ReferralPage: {
                                Field: [
                                    {
                                        name: "CField1",
                                        text: "C Field 1",
                                        required: true
                                    },
                                    {
                                        name: "CField2",
                                        text: "C Field 2"
                                    }
                                ]
                            },
                            ConfigTitle: "Form C"
                        }
                    ]
                };
            }
            break;
        case "DivisionList":
            result = {
                Status: "OK",
                Text: "",
                Result: [
                    {
                        FacilityDescription: "Division Of " + params.FacilityID,
                        FacilityName: "Division Of " + params.FacilityID,
                        CUCODE: null,
                        Disused: true,
                        PLAmount: null,
                        PLPolicyNo: null,
                        PLInsurerName: null,
                        PLExpiryDate: null,
                        PIAmount: null,
                        PIPolicyNo: null,
                        PIInsurerName: null,
                        PIExpiryDate: null,
                        ELAmount: null,
                        ELPolicyNo: null,
                        ELInsurerName: null,
                        ELExpiryDate: null,
                        InsuranceCertificateDisplayed: null,
                        PublicTransport: null,
                        TypeOfPremises: null,
                        D1Planning: null,
                        FireSafetyProcedure: null,
                        AllStaffEnhancedDiscolsure: null,
                        LGMiscllaneusProvisionsAct: null,
                        ExercisePrescriptionSystem: null,
                        AftercareAdviceLeaflets: null,
                        ComplaintsComplimentsPolicy: null,
                        HealthSafetyPolicy: null,
                        InformationSecurityPolicy: null,
                        DataProtectionPolicy: null,
                        EqualityDiversityPolicy: null,
                        CPDTrainingPolicy: null,
                        SafeNeedlingPolicy: null,
                        SafegauardingPolicy: null,
                        MentalHealthRiskPolicy: null,
                        InfectionControlPolicy: null,
                        CompletedAuditTasksCompleted: null,
                        D1PlanningApplicationNumber: null,
                        D1PlanningReason: null,
                        D1PlanningApplicationDate: null,
                        DisusedDate: null,
                        GovPolicyCompliance: null,
                        RiskAssessment: null,
                        ISRegNumber: null,
                        DPP: null,
                        PEEBackgroundChecks: null,
                        DPTrainingProgram: null,
                        SecurityAwarenessTraining: null,
                        SpeedMedicalIdentifier: null,
                        RoleTypeDescription: "Division",
                        Address1: null,
                        Postcode: null,
                        OrganisationID: null,
                        FacilityID: 10,
                        FacilityTypeID: null,
                        FacilityPartOf: params.FacilityID,
                        FacilityRoleTypeId: 2049,
                        ExclusiveClientID: null,
                        ROW_NUMBER: 1
                    }
                ],
                Metadata: {},
                RecordCount: 1
            };
            break;
        case "SaveReferralForm":
            result = {
                Status: "OK",
                Text: "",
                Result: {
                    CaseID: 123,
                    PartyID: 987,
                    CaseRef: "00000987-001"
                },
                Metadata: null,
                RecordCount: 1
            };
            break;
        case "GetBookingSiteConfig":
            if (
                "https://localhost/" === params?.URL ||
                "https://localhost:3000/" === params?.URL
            ) {
                result = {
                    Status: "OK",
                    Text: "",
                    Result: {
                        Token: "valid-token",
                        WebsiteConfigID: 123456789,
                        ClientPartyID: null,
                        ServiceActivityId: 12345,
                        fkClientPartyID: null,
                        BookingURL: "http://localhost/",
                        Title: "Localhost client config",
                        CaptureInjuries: true,
                        ShowNotificationOptions: false,
                        ShowAppTypeReview: false,
                        ShowPatientReview: true,
                        LocationText:
                            "Please ensure that the Member will be in a quiet and confidential place to receive the telephone call from the IPRS Health customer service advisor",
                        ShowLiveHelp: true,
                        LiveHelpURL:
                            "//cdn.synthetix.com/penfield/get_synthetix.min.js?applicationkey=09eb27883c6b2441a1265146337f0a63&consumerkey=d55c9df3224767eca9631494cb1d7bab",
                        CaptureInjuryComments: true,
                        ShowBookAnotherLink: true,
                        ShowAddToDiaryLink: false,
                        ShowGetDirectionsLink: false,
                        ShowAppstoreLinks: false,
                        ShowPortalLinks: false,
                        ConfirmationText:
                            "A confirmation SMS and email have been sent to the patient's email address",
                        CaptureVisual: false,
                        ShowFeedbackStars: false,
                        PhioIntegration: false,
                        NoTimeAvalibleText: null,
                        NoTimeAvalibleLinkText: null,
                        Deleted: false,
                        DateModified: "2023-03-29T22:12:33.207",
                        NumberOfRollingDays: 5,
                        ReferralForm: {
                            Fields: [
                                {
                                    controlType: "heading",
                                    level: "2",
                                    text: "Personal details",
                                    name: "index_1",
                                    required: false,
                                    uniqueID: 1
                                },
                                {
                                    name: "Salutation",
                                    text: "Title",
                                    enabled: "true",
                                    required: false,
                                    controlType: "dropdownlist",
                                    width: 100,
                                    Item: [
                                        {
                                            Text: "Mr",
                                            Value: "Mr"
                                        },
                                        {
                                            Text: "Miss",
                                            Value: "Miss"
                                        },
                                        {
                                            Text: "Mrs",
                                            Value: "Mrs"
                                        },
                                        {
                                            Text: "Dr",
                                            Value: "Dr"
                                        }
                                    ],
                                    Options: [
                                        {
                                            Text: "Mr",
                                            Value: "Mr"
                                        },
                                        {
                                            Text: "Miss",
                                            Value: "Miss"
                                        },
                                        {
                                            Text: "Mrs",
                                            Value: "Mrs"
                                        },
                                        {
                                            Text: "Mx",
                                            Value: "Mx"
                                        }
                                    ],
                                    otherOption: "Other",
                                    uniqueID: 2
                                },
                                {
                                    controlType: "twotextbox",
                                    name: "index_3",
                                    required: false,
                                    field1: {
                                        text: "First Name",
                                        name: "Forename",
                                        required: true
                                    },
                                    field2: {
                                        text: "LastName",
                                        name: "Surname",
                                        required: true
                                    },
                                    uniqueID: 3
                                },
                                {
                                    controlType: "dropdownlist",
                                    name: "Gender",
                                    text: "Gender",
                                    uniqueID: 4,
                                    required: true,
                                    Options: [
                                        {
                                            Text: "Male",
                                            Value: "M"
                                        },
                                        {
                                            Text: "Female",
                                            Value: "F"
                                        }
                                    ],
                                    DataSourceID: 0
                                },
                                {
                                    text: "Date of Birth",
                                    name: "DOB",
                                    controlType: "datetextbox",
                                    required: true,
                                    uniqueID: 5
                                },
                                {
                                    text: "Membership Number",
                                    name: "MembershipNumber",
                                    controlType: "text",
                                    required: true,
                                    uniqueID: 6,
                                    regex: "^\\d{10}$",
                                    validationWarning:
                                        "Please enter a ten digit number"
                                },
                                {
                                    text: "Policyholder / Dependant ",
                                    name: "PolicyHolderOrDependant",
                                    controlType: "dropdownlist",
                                    required: false,
                                    Options: [
                                        {
                                            Text: "Policyholder",
                                            Value: "Policyholder"
                                        },
                                        {
                                            Text: "Dependant",
                                            Value: "Dependant"
                                        }
                                    ],
                                    uniqueID: 7
                                },
                                {
                                    controlType: "break",
                                    name: "index_7",
                                    required: false,
                                    uniqueID: 8
                                },
                                {
                                    controlType: "heading",
                                    level: "2",
                                    text: "Contact information",
                                    name: "index_8",
                                    required: false,
                                    uniqueID: 9
                                },
                                {
                                    controlType: "address",
                                    name: "Address",
                                    required: false,
                                    addressLine1: {
                                        name: "Addr1",
                                        text: "Address Line 1",
                                        required: true
                                    },
                                    addressLine2: {
                                        name: "Addr2",
                                        text: "Address Line 2",
                                        required: false
                                    },
                                    addressLine3: {},
                                    town: {
                                        name: "Town",
                                        text: "Town",
                                        required: true
                                    },
                                    county: {
                                        name: "County",
                                        text: "County",
                                        required: false
                                    },
                                    postcode: {
                                        name: "Postcode",
                                        text: "Postcode",
                                        required: true
                                    },
                                    uniqueID: 10
                                },
                                {
                                    text: "Mobile Number",
                                    name: "MobileTelNo",
                                    controlType: "text",
                                    required: true,
                                    uniqueID: 11,
                                    regex: "^\\+?\\d{9,15}$",
                                    validationWarning:
                                        "Please enter a valid national or international telephone number"
                                },
                                {
                                    text: "Telephone Number",
                                    name: "HomeTelNo",
                                    controlType: "text",
                                    required: true,
                                    uniqueID: 12,
                                    validationWarning:
                                        "Please enter a valid national or international telephone number",
                                    regex: "^\\+?\\d{9,15}"
                                },
                                {
                                    text: "Email Address",
                                    name: "Email",
                                    controlType: "text",
                                    required: true,
                                    uniqueID: 13,
                                    regex: "^[^@]+\\@[^@]+$",
                                    validationWarning:
                                        "Please enter a valid email address"
                                },
                                {
                                    controlType: "break",
                                    name: "index_14",
                                    required: false,
                                    uniqueID: 14
                                },
                                {
                                    controlType: "heading",
                                    level: "2",
                                    text: "Additional Information",
                                    name: "index_15",
                                    required: false,
                                    uniqueID: 15
                                },
                                {
                                    controlType: "text",
                                    name: "ReasonForReferral",
                                    text: "Useful notes",
                                    uniqueID: 25,
                                    required: false,
                                    rows: "5"
                                },
                                {
                                    controlType: "dropdownlist",
                                    name: "SiteOfEmployment",
                                    Options: [
                                        {
                                            Text: "Bournemouth",
                                            Value: "Bournemouth"
                                        },
                                        {
                                            Text: "South Africa",
                                            Value: "South Africa"
                                        },
                                        {
                                            Text: "Stockport",
                                            Value: "Stockport"
                                        },
                                        {
                                            Text: "Not Applicable",
                                            Value: "Not Applicable"
                                        }
                                    ],
                                    text: "Site",
                                    required: true,
                                    uniqueID: 16
                                },
                                {
                                    text: "Outpatient Benefit Remaining",
                                    name: "OutpatientBenefitRemaining",
                                    controlType: "text",
                                    required: true,
                                    uniqueID: 17,
                                    regex: "",
                                    validationWarning: ""
                                },
                                {
                                    text: "Policy Renewal Date",
                                    name: "PolicyRenewalDate",
                                    controlType: "datetextbox",
                                    required: true,
                                    maxDaysAhead: "730",
                                    maxDaysPrevious: "90",
                                    pivotYAhead: "100",
                                    uniqueID: 18
                                },
                                {
                                    text: "Referrer Full Name",
                                    name: "ReferrerFirstName",
                                    controlType: "twotextbox",
                                    required: false,
                                    field1: {
                                        name: "ReferrerFirstName",
                                        text: "Referrer First Name",
                                        required: true
                                    },
                                    field2: {
                                        name: "ReferrerLastName",
                                        text: "Referrer Last Name",
                                        required: true
                                    },
                                    uniqueID: 19
                                },
                                {
                                    text: "Referral Type",
                                    name: "ReferralType",
                                    controlType: "dropdownlist",
                                    width: 100,
                                    Item: [
                                        {
                                            Text: "GP",
                                            Value: "GP"
                                        },
                                        {
                                            Text: "Self Referral",
                                            Value: "Self Referral"
                                        },
                                        {
                                            Text: "Specialist Consultant",
                                            Value: "Specialist Consultant"
                                        }
                                    ],
                                    required: true,
                                    Options: [
                                        {
                                            Text: "GP",
                                            Value: "GP"
                                        },
                                        {
                                            Text: "Self Referral",
                                            Value: "Self Referral"
                                        },
                                        {
                                            Text: "Specialist Consultant",
                                            Value: "Specialist Consultant"
                                        }
                                    ],
                                    uniqueID: 20
                                },
                                {
                                    text: "Hospital List",
                                    name: "HospitalList",
                                    controlType: "dropdownlist",
                                    width: 100,
                                    Item: [
                                        {
                                            Text: "Countrywide",
                                            Value: "Countrywide"
                                        },
                                        {
                                            Text: "Premier",
                                            Value: "Premier"
                                        },
                                        {
                                            Text: "Local",
                                            Value: "Local"
                                        },
                                        {
                                            Text: "Consultant Select",
                                            Value: "Consultant Select"
                                        },
                                        {
                                            Text: "VGP Select",
                                            Value: "VGP Select"
                                        }
                                    ],
                                    required: true,
                                    Options: [
                                        {
                                            Text: "Local / Countrywide",
                                            Value: "Local / Countrywide"
                                        },
                                        {
                                            Text: "London Care",
                                            Value: "London Care"
                                        },
                                        {
                                            Text: "Consultant Select",
                                            Value: "Consultant Select"
                                        }
                                    ],
                                    uniqueID: 21
                                },
                                {
                                    text: "Excess Amount",
                                    name: "ExcessAmount",
                                    controlType: "text",
                                    required: true,
                                    uniqueID: 22,
                                    regex: "^\\d{1,4}(\\.\\d{1,2})?$",
                                    validationWarning:
                                        "Please enter a valid amount in pounds"
                                },
                                {
                                    text: "Is this a Tier One Corporate Client?",
                                    name: "TopTier",
                                    controlType: "checkbox",
                                    required: false,
                                    uniqueID: 23
                                },
                                {
                                    controlType: "info",
                                    name: "newField_407858",
                                    text: "Change this",
                                    uniqueID: 24,
                                    subFields: [
                                        {
                                            text: "List of tier one clients",
                                            type: "span"
                                        },
                                        {
                                            text: "Ashurst",
                                            type: "li"
                                        },
                                        {
                                            text: "Bloomberg",
                                            type: "li"
                                        },
                                        {
                                            text: "Tradition",
                                            type: "li"
                                        },
                                        {
                                            text: "Salesforce",
                                            type: "li"
                                        },
                                        {
                                            text: "Michael Page International Recruitment Ltd (formerly known as Page Group)",
                                            type: "li"
                                        }
                                    ],
                                    required: false
                                },
                                {
                                    controlType: "break",
                                    name: "newField_944976",
                                    text: "Change this",
                                    uniqueID: 26,
                                    required: false
                                },
                                {
                                    controlType: "info",
                                    name: "newField_645598",
                                    text: "Change this",
                                    uniqueID: 27,
                                    subFields: [
                                        {
                                            text: "Your personal information provided to us will be used for the purposes of booking your appointment. To find out more about our promise to protect you personal information, please see our ",
                                            type: "span"
                                        },
                                        {
                                            text: "Privacy Policy",
                                            type: "a",
                                            href: "https://www.iprshealth.com/about-us/gdpr/patientprivacynotice/"
                                        }
                                    ],
                                    required: false
                                }
                            ]
                        },
                        RecaptureRequired: true,
                        AppointmentTypes: [
                            {
                                AppointmentTypeID: 1,
                                Title: "",
                                Description: "Appointment Type 1",
                                DefaultLength: 20,
                                LeadTimeMins: 60,
                                ImageURL: ""
                            }
                        ],
                        Clients: [
                            {
                                ClientPartyID: 9901,
                                Name: "VitalityHealth"
                            },
                            {
                                ClientPartyID: 9902,
                                Name: "VitalityHealth MSK Post Op"
                            },
                            {
                                ClientPartyID: 9903,
                                Name: "VitalityHealth Staff Scheme"
                            },
                            {
                                ClientPartyID: 9904,
                                Name: "VitalityHealth NOC6"
                            }
                        ],
                        AppointmentTypeRestrictions: [
                            {
                                ServiceActivityID: 1002,
                                IncludeAppointmentTypeIDs: []
                            }
                        ],
                        InjuryRegionRestrictions: {
                            MaxNumberOfInjuries: 3,
                            Restrictions: [
                                {
                                    ClientID: 9901,
                                    ServiceActivityID: 1002,
                                    MaxNumberOfInjuries: 1
                                },
                                {
                                    ClientID: 9903,
                                    ServiceActivityID: 1002,
                                    MaxNumberOfInjuries: 1
                                }
                            ]
                        },
                        ServiceActivities: [
                            {
                                ClientPartyIDs: [9901, 9903],
                                ServiceActivityID: 1001,
                                FriendlyName: "Hybrid Call"
                            },
                            {
                                ClientPartyIDs: [9901, 9903],
                                ServiceActivityID: 1002,
                                FriendlyName: "F2F Treatment"
                            }
                        ],
                        AdditionalData: {},
                        DataSourceValues: {}
                    },
                    Metadata: null,
                    RecordCount: 1
                };
            }
            break;
        case "InjuryRegionList":
            result = {
                Status: "OK",
                Text: "",
                Result: [
                    {
                        ROW_NUMBER: 1,
                        pkRegionId: 220,
                        MedicalRegion: "Abdomen/Lower trunk",
                        RowVersion: "AAAAAAfxc0E=",
                        NonMedicalRegion: "Stomach / Abdomen",
                        DateTo: null,
                        fkInjuryRegionTypeID: 1,
                        ICD10Code: "R10                 ",
                        ICD11Code: "                    "
                    },
                    {
                        ROW_NUMBER: 2,
                        pkRegionId: 290,
                        MedicalRegion: "Ankle/Achilles Tendon",
                        RowVersion: "AAAAAAXqqjU=",
                        NonMedicalRegion: "Ankle/Achilles Tendon",
                        DateTo: null,
                        fkInjuryRegionTypeID: 1,
                        ICD10Code: "M25.57              ",
                        ICD11Code: null
                    }
                ]
            };
            break;
        case "GetBookingSlots":
            result = {
                Status: "OK",
                Text: "",
                Result: [
                    {
                        StartTime: "2025-11-02T08:40:00",
                        FinishTime: "2025-11-02T09:00:00"
                    },
                    {
                        StartTime: "2025-11-02T09:00:00",
                        FinishTime: "2025-11-02T09:20:00"
                    },
                    {
                        StartTime: "2025-11-08T17:20:00",
                        FinishTime: "2025-11-08T17:40:00"
                    }
                ],
                Metadata: null,
                RecordCount: 141
            };
            break;
        case "HoldBookingSlot":
            result = {
                Status: "OK",
                Text: "",
                Result: {
                    BookingID: 123456789,
                    StartTime: "2025-11-02T08:40:00",
                    FinishTime: "2025-11-02T09:00:00"
                },
                Metadata: null,
                RecordCount: 1
            };
            break;
        case "IsPaymentCardRequired":
            result = {
                Status: "OK",
                Text: "",
                Result: {
                    IsPaymentCardRequiredNew: true
                }
            };
            break;
        case "AddressLookup":
            result = {
                Status: "OK",
                Text: "",
                Result: {
                    Address1: "Address1",
                    Address2: "Address2",
                    Address3: "Address3",
                    County: "County",
                    Town: "Town",
                    Postcode: "Postcode"
                }
            };
        default:
            result = {
                Status: "ERROR",
                Text: `Don't understand (${messageID})`,
                Result: `Don't understand (${messageID})`,
                error: `Don't understand (${messageID})`
            };
            console.log(`Don't understand (${messageID})`, params);
    }

    //console.log(`Processing (${messageID})`, params);

    return result;
};

export default demoMockNetwork;
