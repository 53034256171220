import wr527Fudges from "./wr527Fudges";
import DataCapture, {
    FormStateConsts,
    websiteStages
} from "../FormDesigner/Form/DataCapture";
import toBoolean from "../../lib/iprs-react-library/src/util/toBoolean";

const DEV_WEB_CONFIG_ID = 34566456;
const PROD_WEB_CONFIG_ID = 34763845;

const updateWithConfig = (webConfig, newDC) => {

    if(DEV_WEB_CONFIG_ID === webConfig?.WebsiteConfigID || 
        PROD_WEB_CONFIG_ID === webConfig?.WebsiteConfigID){
        newDC.websiteConfig = wr527Fudges(webConfig);
    }else{
        // pass the whole of the config to be stored on the app state
        newDC.websiteConfig = webConfig;
    }

    

    newDC.activeStages = [...websiteStages];
    if (newDC.configMode) {
        // Finish doesn't display in the progress bar in live site
        newDC.activeStages.push([FormStateConsts.Finish, "Finish"]);
        newDC.activeStages.push([
            FormStateConsts.Configuration,
            "Configuration"
        ]);
    }

    const Fields = newDC.websiteConfig?.ReferralForm?.Fields;

    if (Fields) {
        newDC.websiteConfig.ReferralForm = {};
        newDC.websiteConfig.ReferralForm.Fields = Fields.map((f, i) => {
            // make sure every field has a name and a lower case controlType
            const name = f.name ? f.name : "index_" + i;
            const controlTypeTmp = f.controlType
                ? f.controlType.toLowerCase()
                : "text";
            const controlType =
                "textbox" === controlTypeTmp ? "text" : controlTypeTmp;
            const required =
                "true" === f.required || true === f.required ? true : false;

            const fieldCopy = { ...f, name, controlType, required };

            if ("dropdownlist" === controlType) {
                const options = f.Options ? f.Options : f.Item;

                fieldCopy.Options = options ? options : [];
            }

            return fieldCopy;
        });
    } else {
        newDC.websiteConfig.ReferralForm = null;
    }

    const patient = newDC.websiteConfig.Patient;
    newDC.personalData = {
        ...patient,
        DOB: patient?.BirthDate ?? null,
        personTitle: patient?.PersonalTitle ?? null,
        Forename: patient?.FirstName ?? null,
        Surname: patient?.LastName ?? null
    };

    if (!newDC.Client) {
        if (1 === newDC.websiteConfig?.Clients?.length) {
            newDC.Client = newDC.websiteConfig.Clients[0];
        } else {
            newDC.Client = {};
        }
    }
    if (!newDC?.Client?.ClientPartyID) {
        newDC.Client.ClientPartyID = newDC.websiteConfig.ClientPartyID;
    }

    newDC.serviceActivityId = newDC.websiteConfig.ServiceActivityId;
    newDC.serviceId = newDC.websiteConfig?.ServiceId;

    newDC.captureInjuries = newDC.websiteConfig.CaptureInjuries;
    newDC.ServiceActivities = Array.isArray(newDC.websiteConfig?.ServiceActivities) ?
        [...newDC.websiteConfig.ServiceActivities] : [];

    newDC.appointmentTypes = newDC.websiteConfig.AppointmentTypes;
    if (1 === newDC.websiteConfig.AppointmentTypes?.length) {
        newDC.appointmentType = newDC.appointmentTypes[0];
        if (!newDC.websiteConfig.CaptureInjuries && !newDC.websiteConfig?.CaptureVisual) {
            newDC.activeStages = newDC.activeStages.filter(
                s => s[0] !== FormStateConsts.AppointmentCapture
            );
        }
    }

    if (!toBoolean(newDC.websiteConfig.CaptureAssessmentType)) {
        newDC.activeStages = newDC?.activeStages?.filter(
            s => s[0] !== FormStateConsts.AssessmentTypeCapture
        );
    }else if(!newDC.websiteConfig.CaseID){
        // as there is no case, and the backend can only handle
        // an assessment type for a referred case, we need to
        // move the assessment type capture to the end
        const personIndex = newDC.activeStages.findIndex(
            s => s[0] === FormStateConsts.PersonCapture
        );
        const assessmentIndex = newDC.activeStages.findIndex(
            s => s[0] === FormStateConsts.AssessmentTypeCapture
        );
        if (personIndex > -1 && assessmentIndex > -1) {
            newDC.activeStages.splice(assessmentIndex, 0, newDC.activeStages.splice(personIndex, 1)[0]);
        }
    }

    if (!toBoolean(newDC.websiteConfig.TakePayment)) {
        newDC.activeStages = newDC?.activeStages?.filter(
            s => s[0] !== FormStateConsts.PaymentCapture
        );
    }

    if (newDC.websiteConfig.CaseID) {
        newDC.caseID = newDC.websiteConfig.CaseID;
        // We already have a case so we can't pick a client, service activity 
        // or enter person details
        newDC.activeStages = newDC.activeStages.filter(
            s => (s[0] !== FormStateConsts.PersonCapture) 
            && (s[0] !== FormStateConsts.ServiceCapture)
            && (s[0] !== FormStateConsts.ClientCapture)
        );
    } else if (!newDC.websiteConfig.ReferralForm && !newDC.configMode) {
        newDC.pageState = FormStateConsts.Error;
        newDC.error = "You have arrived at this site through an invalid link.";
    }
    if (2 > newDC.websiteConfig.Clients?.length) {
        newDC.activeStages = newDC.activeStages.filter(
            s => s[0] !== FormStateConsts.ClientCapture
        );
    }
    if (2 > newDC.websiteConfig.ServiceActivities?.length) {
        newDC.activeStages = newDC.activeStages.filter(
            s => s[0] !== FormStateConsts.ServiceActivities
        );
    }
    newDC.Clients = newDC.websiteConfig.Clients;
    newDC.captureInjuries = newDC.websiteConfig.CaptureInjuries;
    newDC.serviceActivityId = newDC.websiteConfig.ServiceActivityId;

    if (!newDC.websiteConfig.Token && !newDC.configMode) {
        newDC.pageState = FormStateConsts.Error;
        newDC.error = "Please use the booking link sent to you by email.";
    }
};

export default updateWithConfig;