import React, { useEffect, useCallback } from "react";
import { decodeJWT } from "../../lib/iprs-react-library/src/index.js";
import PaymentForm from "./PaymentForm";

const Payment = ({ api, update }) => {
    const [error, setError] = React.useState(null);

    const setPaymentResult = useCallback(
        result => {
            if (/^success$/i.test(result)) {
                update({ CardPaymentCheckDone: true });
            } else {
                setError("Payment failed");
            }
        },
        [update]
    );

    return (
        <div>
            {error && <div>Error: {error}</div>}
            <PrePaymentNotice />
            {error && <div>Error: {error}</div>}
            <PaymentForm
                api={api}
                setPaymentResult={setPaymentResult}
                token={api.getToken()}
            />
            {error && <div>Error: {error}</div>}
        </div>
    );
};

const paragraph = {
    fontSize: "1.2em",
    marginBottom: "1.5em"
};

const PrePaymentNotice = () => (
    <div
        style={{
            lineHeight: 1.3
        }}
    >
        <h2
            style={{
                color: "#D75094",
                fontSize: "200%",
                fontWeight: 600,
                lineHeight: 3,
                marginBottom: 0
            }}
        >
            Arrange your Virtual Physiotherapy Appointment
        </h2>

        <p
            style={{
                fontSize: "150%",
                marginBottom: "1em"
            }}
        >
            Before you book your virtual physiotherapy appointment we need to
            collect payment card details from you.
        </p>

        <p
            style={{
                fontWeight: 600,
                marginBottom: "1em"
            }}
        >
            Payment card details are taken in case you become liable for any
            costs in relation to your treatment. This could be for the following
            reasons:
        </p>

        <p style={paragraph}>
            If your insurance policy is cancelled, lapsed, or expires and you
            continue to receive treatment, you would be responsible for the
            costs of the treatment from the point of no cover.
        </p>

        <p style={paragraph}>
            If you cancel an appointment and fail to give 24 hours' notice that
            you are unable to attend, or if you do not show up for an
            appointment, you may be charged a no show fee, this is not covered
            by your insurance policy, so is passed on to you.
        </p>

        <p style={paragraph}>
            If you have an excess on your policy or if you exceed any policy
            benefit limits your insurer will advise us of this and we will
            request a payment from you to cover these areas.
        </p>

        <p style={paragraph}>
            To streamline your onward journey, we would like to validate a
            payment card, that we will hold securely and only use if one of the
            above scenarios occur during your treatment with us. To validate
            your card a £1.00 validation transaction is processed; however, this
            is refunded back to you, by IPRS Health.
        </p>

        <p style={paragraph}>
            If IPRS Health do debit your card, we always provide you with 5
            working days' notice, by email. The email will state the reasons why
            we need to debit the card, the amount, and our contact details in
            case you have any questions.
        </p>

        <p
            style={{
                fontSize: "1.2em",
                marginBottom: "2em"
            }}
        >
            Please provide below the card details that you would like IPRS
            Health to validate.
        </p>
    </div>
);

const PaymentRequiredCheckComponent = ({ api, update, appState }) => {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    useEffect(() => {
        const decodedToken = decodeJWT(api.getToken());
        const params = {
            caseid: decodedToken.CaseID
        };
        api.transact("IsPaymentCardRequired", params)
            .then(r => r.apiResult)
            .then(r => {
                setLoading(false);
                // backward compatible with earlier API version
                const isPaymentCardRequired = Object.hasOwn(
                    r,
                    "IsPaymentCardRequiredNew"
                )
                    ? r.IsPaymentCardRequiredNew
                    : true; // with the old API, we assume that payment is required
                if (!isPaymentCardRequired) {
                    // no payment required so just tell the app that the payment check is done
                    update({ CardPaymentCheckDone: true });
                }
            })
            .catch(e => {
                setError(e.message);
            });
    }, [api, update]);

    return (
        <div>
            {loading && (
                <div>
                    Checking whether we need to take a card payment from you.
                </div>
            )}
            {error && <div>Error: {error}</div>}
            {!loading && !error && (
                <Payment api={api} update={update} appState={appState} />
            )}
        </div>
    );
};

export default PaymentRequiredCheckComponent;
