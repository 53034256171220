import React, { useReducer, useCallback, useEffect }
                                            from 'react';

import {bootstrap}                      
                                            from './BootstrapConfig';
import {
    AccountMenu, TwoSwitch,
    PageFoot, LoginOverlay
}
                                            from  "../../lib/iprs-react-library/src/index";
import DataCapture, {FormStateConsts}       
                                            from '../FormDesigner/Form/DataCapture';
import APPRender                            from './AppRender';

import                                           './App.css';

import reducer                              from './Reducer';
import FormProgress                         from './FormProgress';

const PUBLIC_URL                        = process.env.PUBLIC_URL;
const configMode = false;

/**
 * The main application REACT component. 
 * Note that the main app logic is in the Reducer.js file.
 * The configuration app is now separate in ConfigApp.js.
 * @returns {String} Renderable JSX
 */
const App = ({api}) => {
    
    const initialState          = new DataCapture();
    initialState.api            = api;
    initialState.configMode     = configMode;

    /* Store of the application state 
     * Note getReducer just gets a reducer function that is bound to the api */
    const [appState, doUpdate] = useReducer(reducer, initialState);

    /* Cause the bootsstrap function to run whenever the state transitions
     * to 'Bootstraping' */
    useEffect( () => bootstrap(api, doUpdate), [api]);

    const handleLoginEvent = useCallback((actionName) => {

        doUpdate({ handleLoginEvent: actionName });
    }, []);

    const handleFormProgClick = useCallback(e => {
        e.preventDefault();
        doUpdate({ changePageState: e.target.name });
    }, [doUpdate]);

    const formProgProps = {
        progress: appState.pageState,
        activeStages: appState.activeStages,
        handleClick: configMode ? handleFormProgClick : null
    };

    return <div className="App">
        <div className="typography">
            <div className="container">
                
                <main className="main">
                    <div className="container">
                        <header className="topHeader main__top">
                            <div className="main__top-logo">
                                <img src={PUBLIC_URL + '/images/iprshealth.svg'} alt="IPRS Health" />
                            </div>
                            <h1 className="main__top-title">
                                Welcome to IPRS Health's Services
                            </h1>
                            <AccountMenu api={api} handleLoginEvent={handleLoginEvent} />
                        </header>
                    </div>

                    <FormProgress {...formProgProps} />

                    <section className="main__section">
                        <TwoSwitch test={!configMode || api.isLoggedIn()} >
                            <APPRender doUpdate={doUpdate} api={api} appState={{ ...appState, designMode: configMode }} />
                            <div className="container typography login_container" >
                                <main>
                                    <LoginOverlay handleLoginEvent={handleLoginEvent} api={api} />
                                </main>
                            </div>
                        </TwoSwitch>
                    </section>
                </main>

                
            </div>
        </div>

        <PageFoot />
    </div>
}

export default App;
