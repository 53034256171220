import { useState, useEffect, useCallback } from "react";

const store = window.sessionStorage;

/* These are used to determin which screen is rendered. */
const FormStateConsts = Object.freeze({
    Bootstraping: "Bootstraping", // initialisation - no screen
    AssessmentTypeCapture: "AssessmentTypeCapture", // Assessment type screen if the build supports it
    PaymentCapture: "PaymentCapture", // Take payment if necessary
    AppointmentCapture: "AppointmentCapture",
    TimeCapture: "TimeCapture",
    PersonCapture: "PersonCapture",
    Review: "Review",
    Finish: "Finish",
    Error: "Error", // Display an error
    ClientCapture: "ClientCapture",
    ServiceCapture: "ServiceCapture", // ServiceActivity
    Configuration: "Configuration" // Configuiration screen if the build supports it
});

const defaultPageState = FormStateConsts.Bootstraping;

/* This is the list of stages in the booking process that the user sees. The
 * others are hidden as they don't display in progress bar. Depending upon
 * the site configuration, different stages will show as available */
const websiteStages = [
    [FormStateConsts.ClientCapture, "Client"],
    [FormStateConsts.ServiceCapture, "Service Capture"],
    [FormStateConsts.AssessmentTypeCapture, "Assesment Type"],
    [FormStateConsts.PaymentCapture, "Card Payment"],
    [FormStateConsts.AppointmentCapture, "Appointment Type"],
    [FormStateConsts.TimeCapture, "Date and Time"],
    [FormStateConsts.PersonCapture, "Personal Details"],
    [FormStateConsts.Review, "Review"]
];

/**
 * A class to hold captured data from the booking process for final submisition.
 * This really is just to create clear placeholder fields.
 * This class gets used both for holding captured data an signalling changes.
 */
class DataCapture {
    // an object which includes the appoinment type and type of injuries.
    appointmentType = null;
    appointmentTypes = []; // description etc
    captureInjuries = null;
    referralForm = null;
    injuries = [];
    startTime = null; // String for appoinment time
    endTime = null; // String for appoinment end time
    bookingId = -1;
    caseID = "";
    error = null;
    pageState = defaultPageState;

    configMode = false;

    // clients that can be selected
    Clients = [];
    ServiceActivities = [];
    Client = null; // the client selected
    SelectedClients = [];

    // signal a cancel from the user
    doCancel = false;

    clientID = 0;
    serviceActivityId = 0;

    activeStages = websiteStages;

    /**
     * recreate this object from JSON
     * @param {type} json
     * @returns {DataCapture}
     * @throws {Error} If the JSON is invalid
     */
    static deserialize(json) {
        const newObject = new DataCapture();

        if (false && json) {
            const ob = JSON.parse(json);

            // reset any non storable state to it's default
            //ob.pageState    = defaultPageState;

            Object.assign(newObject, ob);
        }

        return newObject;
    }

    serialize() {
        const cloned = this.clone();

        return JSON.stringify(cloned);
    }

    clone() {
        const cloned = new DataCapture();
        Object.assign(cloned, this);
        //cloned.#pageState = this.#pageState;
        return cloned;
    }
}

/* this is purely a wrapper around useState which saves the state every time
it is changed. Storage is just session storage so we avoid storing personal 
data after the browser session ends. */
const useSavedState = key => {
    const getStored = useCallback(() => {
        const retreived = store.getItem(key);
        let result;
        try {
            result = DataCapture.deserialize(retreived);
        } catch (error) {
            // the existing result caused a problem so clear it
            store.setItem(key, null);
            result = new DataCapture();
        }
        return result;
    }, [key]);

    const [value, setValue] = useState(getStored());

    const setAndSave = newVal => {
        store.setItem(key, newVal.serialize());

        const newValCpy = newVal.clone();
        setValue(newValCpy);
    };

    useEffect(() => {
        setValue(getStored());
    }, [getStored, setValue]);

    return [value, setAndSave];
};

export default DataCapture;
export { FormStateConsts, useSavedState, websiteStages };
