import React, { useState } from "react";
import phoneImage from "./img/phone-image.jpg";
import talkingOnHeadset from "./img/talking-on-headset.jpg";
import PhoneNumber from "./PhoneNumber";

import saveReferral                     from '../PersonCapture/Referral';

import './styles.css';
import './sit2kvo.css';
import { LoadingIndicator } from "../../lib/iprs-react-library/src";

const ServiceSelection = ({api, update, appState}) => {

    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');

    const handleClick = (e) => {
        const parentTarget = e.target.closest('.feature-box');
        const assessmentChoice = parentTarget?.getAttribute('name');
        const assesmentType = assessmentChoice === 'DigitalAssesment' ? 1 : 2;
        const isClinicalAssesment = assessmentChoice === 'ClinicalAssesment';
        console.log('name',assessmentChoice);
        
        setLoading(true);

        const referralPromise= appState.caseID?
            Promise.resolve(null):
            saveReferral(api, appState).then( r => r.apiResult );

        referralPromise
            .then(r => {

                let token, caseID, caseRef;
                if (r) {
                    // A bit ugly but parse r.BokingURL to get token
                    // this token will include the case ID just created
                    const url = new URL(r.BookingURL);
                    token = url.searchParams.get("token");
                    caseID = r.CaseID;
                    caseRef = r.CaseRef;
                }else{
                    token = api.getToken();
                    caseID = appState.caseID;
                    caseRef = appState.caseRef;
                }

                const params = {
                    "Token": token,
                    'AssessmentType': assesmentType,
                    "AssessmentTypeString": assessmentChoice
                };

                return api
                    .transact("ConfirmAssessmentType", params)
                    .then(r => r.apiResult)
                    .then(r => {
                        setLoading(false);
                        setErr("");
                        const nextURL = r.NextURL;
                        if (isClinicalAssesment) {
                            update({
                                AssesmentType: assessmentChoice,
                                nextURL,
                                CaseID: caseID?? null,
                                CaseRef: caseRef?? null
                            });
                        } else {
                            window.location.href = nextURL;
                        }
                    });
            })
            .catch(err => {
                setLoading(false);
                setErr(err.message);
            });
    };

    return <ServiceSelectionInternal handleClick={handleClick} brandingInfo={{}} loading={loading} err={err}/>
}

const ServiceSelectionInternal = ({ handleClick, brandingInfo, loading, err }) => {
    return (
        <div className="assessment-type-selection">
            <h1>Service Selection</h1>
            <LoadingIndicator loading={loading} />
            {err && <p>Sorry there was an error: {err}</p>}
            <p>Please confirm the assessment type that suits you best:</p>
            <div className="feature-boxes" ng-controller="OptionController">
                <FeatureBox
                    onClick={handleClick}
                    name="DigitalAssesment"
                    img={phoneImage}
                    altText="Man looking at mobile phone"
                    infoText1="Our digital service allows you to instantly complete your assessment with our clinically validated chatbot Phio Access. You can complete the assessment on all mobile and desktop devices 24/7."
                    infoText2="The assessment will take around 15 minutes to complete. At the end of your assessment you will be advised on the most suitable pathway to progress your care. This digital journey is overseen by our experienced team of Physiotherapists."
                    text="Start your digital assessment now"
                />
                <FeatureBox
                    onClick={handleClick}
                    name="ClinicalAssesment"
                    img={talkingOnHeadset}
                    alt="Man talking on headset"
                    infoText1="Our telephone or video assessments are conducted by experienced physiotherapists. If you select this option you will be taken to an online diary where you can arrange a convenient time to complete the assessment. If you can't find a time to suit you then you can always come back and commence our digital assessment 24/7."
                    infoText2="Telephone and video assessments may take around 30 minutes. At the end of your assessment, our Physiotherapist will discuss the most appropriate treatment plan for you."
                    text="Book your telephone or video clinical assessment"
                />
            </div>

            <p className="bottom-paragraph">
                Need assistance? Please contact IPRS Health on <PhoneNumber brandingInfo={brandingInfo} />.
            </p>
        </div>
    );
};

const FeatureBox = ({
    onClick,
    name,
    img,
    altText,
    infoText1,
    infoText2,
    text
}) => {
    const [showInfo, setShowInfo] = useState(false);
    const boxClass = showInfo
        ? "feature-box__info feature-box__hover-div active"
        : "feature-box__info feature-box__hover-div hidden";
    const opacity = showInfo ? 1 : 0;
    const infoBoxToggle = e => {
        e.stopPropagation();
        setShowInfo(!showInfo);
    };

    return (
        <div
            className={"feature-box"}
            onClick={onClick}
            aria-labelledby={`selectBox-${name}`}
            name={name}
        >
            <img src={img} alt={altText} />
            <p className="green-button">{text}</p>
            <button className="info__button" onClick={infoBoxToggle}>
                <InfoImgSVG />
            </button>
            <div className={boxClass} style={{ opacity: opacity }} id={`selectBox-${name}`} >
                <span>{infoText1}</span>
                <br />
                <span>{infoText2}</span>
                <p className="green-button">{text}</p>
            </div>
        </div>
    );
};

const InfoImgSVG = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="37"
        viewBox="0 0 37 37"
    >
        <g
            id="Group_401"
            data-name="infogroup1"
            transform="translate(-1084 -457)"
        >
            <circle
                id="info1"
                data-name="info1"
                cx="18.5"
                cy="18.5"
                r="18.5"
                transform="translate(1084 457)"
                fill="#d75094"
            />
            <text
                id="i"
                transform="translate(1099 467)"
                fill="#fff"
                fontSize="20"
                fontFamily="neo-sans, sans-serif"
                fontWeight="700"
            >
                <tspan x="0" y="15">
                    i
                </tspan>
            </text>
        </g>
    </svg>
);

export default ServiceSelection;
